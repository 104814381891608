<script lang="ts" setup>
import { useAuthStore } from '@/stores/auth';
import useSignInWithGoogleSuccessHandler from '@/composables/helpers/useSignInWithGoogleSuccessHandler';
import SignInWithGoogleButton from '@/components/Auth/SignInWithGoogleButton.vue';

const { loginWithGoogle } = useAuthStore();

const handleOnSuccess = useSignInWithGoogleSuccessHandler(loginWithGoogle);
</script>

<template>
  <ClientOnly>
    <SignInWithGoogleButton :on-success="handleOnSuccess">
      Log In with Google
    </SignInWithGoogleButton>
  </ClientOnly>
</template>
