<script setup>
import { useAuthStore } from '@/stores/auth/index.ts';

const { logIn } = useAuthStore();

definePageMeta({
  layout: 'auth',
  middleware: ['auth-route']
});

useHead({
  title: 'Login'
});
</script>

<template>
  <Container class-name="h-full flex justify-center items-center py-10">
    <AuthPageWrapper>
      <div class="grid gap-3">
        <h1 class="text-2xl font-medium text-brand-navy-700 text-center">
          Log In to Handle
        </h1>
        <p class="text-lg leading-6 text-brand-navy-700 text-center">
          Don’t have an account?
          <NuxtLink to="/sign-up" class="font-bold underline">
            Sign up here
          </NuxtLink>
        </p>
      </div>
      <BenefitsList />
      <div class="grid gap-2">
        <LoginWithGoogle />
        <Divider />
        <LoginForm :on-login="logIn" />
      </div>
    </AuthPageWrapper>
  </Container>
</template>

<style lang="scss" scoped></style>
